import React from "react";
import { useStaticQuery, graphql } from "gatsby";

import Layout from "../components/Layout";
import SEO from "../components/Seo";

import { Footer } from "../components/sections/Footer";
import { Signup } from "../components/sections/Signup";

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      socialImage: file(relativePath: { eq: "social-image.jpg" }) {
        childImageSharp {
          resize(width: 1200) {
            src
            height
            width
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO
        title="Sign up to boost your ad spending and ROAS"
        image={data.socialImage.childImageSharp.resize}
      />

      {/* <Navigation /> */}
      {/* <Header /> */}
      <Signup />
      <Footer />
    </Layout>
  );
};

export default IndexPage;
