import React from "react";
import { Link } from "gatsby";

export const Signup = () => {
  return (
    <div className="bg-white py-16 px-4 overflow-hidden sm:px-6 lg:px-8 lg:py-24">
      <div className="relative max-w-xl mx-auto">
        <svg
          className="absolute left-full transform translate-x-1/2"
          width="404"
          height="404"
          fill="none"
          viewBox="0 0 404 404"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="404"
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <svg
          className="absolute right-full bottom-0 transform -translate-x-1/2"
          width="404"
          height="404"
          fill="none"
          viewBox="0 0 404 404"
        >
          <defs>
            <pattern
              id="85737c0e-0916-41d7-917f-596dc7edfa27"
              x="0"
              y="0"
              width="20"
              height="20"
              patternUnits="userSpaceOnUse"
            >
              <rect
                x="0"
                y="0"
                width="4"
                height="4"
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width="404"
            height="404"
            fill="url(#85737c0e-0916-41d7-917f-596dc7edfa27)"
          />
        </svg>
        <div className="text-center">
          <h1 className="text-3xl leading-9 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
            Request a free trial
          </h1>
          <p className="mt-4 text-lg leading-6 text-gray-500">
            Test Tagrush on your website for free. No obligation, no payment and
            no credit card required.{" "}
          </p>
        </div>
        <div className="mt-12">
          <form
            className="grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
            id="signup"
            name="signup"
            method="post"
            netlify-honeypot="bot-field"
            data-netlify="true"
          >
            <input type="hidden" name="form-name" value="signup" />
            <input type="hidden" name="bot-field" />
            <div className="sm:col-span-2">
              {/* <fieldset>
                <legend id="radiogroup-label" className="sr-only">
                  Server size
                </legend>
                <ul
                  className="space-y-4"
                  role="radiogroup"
                  aria-labelledby="radiogroup-label"
                >
                  <li
                    id="radiogroup-option-0"
                    tabIndex="0"
                    role="radio"
                    aria-checked="true"
                    className="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <div className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                      <div className="flex items-center">
                        <div className="px-6 text-sm">
                          <p className="sm:inline sm:mx-1 font-medium text-indigo-700 bg-indigo-100 px-3 py-2 rounded-md">
                            Free Trial
                          </p>
                        </div>
                        <div className="text-sm">
                          <p className="font-medium text-gray-900">
                            Growth Plan
                          </p>
                          <div className="text-gray-500">
                            <p className="sm:inline">
                              Shield Facebook Pixel and 50+ scripts
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                        <div className="font-medium text-gray-900 line-through">
                          $79
                        </div>
                        <div className="ml-1 text-gray-500 sm:ml-0">/mo</div>
                      </div>
                    </div>
                    <div
                      className="border-indigo-500 absolute inset-0 rounded-lg border-2 pointer-events-none"
                      aria-hidden="true"
                    ></div>
                  </li>

                  <li
                    id="radiogroup-option-1"
                    tabIndex="-1"
                    role="radio"
                    aria-checked="false"
                    className="group relative bg-white rounded-lg shadow-sm cursor-pointer focus:outline-none focus:ring-1 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    <div className="rounded-lg border border-gray-300 bg-white px-6 py-4 hover:border-gray-400 sm:flex sm:justify-between">
                      <div className="flex items-center">
                        <div className="px-6 text-sm">
                          <p className="sm:inline sm:mx-1 font-medium text-gray-700 bg-gray-100 px-3 py-2 rounded-md">
                            Free Trial
                          </p>
                        </div>
                        <div className="text-sm">
                          <p className="font-medium text-gray-900">Lite Plan</p>
                          <div className="text-gray-500">
                            <p className="sm:inline">
                              Shield Google Ads and Facebook Pixel
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="mt-2 flex text-sm sm:mt-0 sm:block sm:ml-4 sm:text-right">
                        <div className="font-medium text-gray-900 line-through">
                          $29
                        </div>
                        <div className="ml-1 text-gray-500 sm:ml-0">/mo</div>
                      </div>
                    </div>
                    <div
                      className="border-transparent absolute inset-0 rounded-lg border-2 pointer-events-none"
                      aria-hidden="true"
                    ></div>
                  </li>
                </ul>
              </fieldset> */}
              <fieldset>
                <legend className="sr-only">Pricing plans</legend>
                <ul className="relative bg-white rounded-md -space-y-px">
                  <li>
                    <div className="relative border border-gray-200 rounded-tl-md rounded-tr-md p-4 flex flex-col md:pl-4 md:pr-6 md:grid md:grid-cols-3">
                      <label className="flex items-center text-sm leading-5 space-x-3 cursor-pointer">
                        <input
                          aria-label="Growth Plan"
                          id="plan_growth"
                          name="plan"
                          type="radio"
                          value="growth"
                          checked
                          className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out cursor-pointer"
                        />
                        <span className="font-medium text-gray-900">
                          Growth Plan
                        </span>
                      </label>
                      <p
                        id="plan-option-pricing-0"
                        className="ml-6 pl-1 text-sm leading-5 md:ml-0 md:pl-0 md:text-center"
                      >
                        <span className="font-medium text-indigo-700 bg-indigo-100 px-3 py-2 rounded-md">
                          Free Trial
                        </span>
                        <span className="line-through px-2">($79 / mo)</span>
                      </p>
                      <p
                        id="plan-option-limit-0"
                        className="ml-6 pl-1 text-sm leading-5 md:ml-0 md:pl-0 md:text-right"
                      >
                        <strong>
                          Shield Google, Facebook Pixel and 50+ scripts
                        </strong>
                      </p>
                    </div>
                  </li>
                  <li>
                    <div className="relative border border-gray-200 rounded-bl-md rounded-br-md p-4 flex flex-col md:pl-4 md:pr-6 md:grid md:grid-cols-3">
                      <label className="flex items-center text-sm leading-5 space-x-3 cursor-pointer">
                        <input
                          aria-label="Standard Plan"
                          id="plan_standard"
                          name="plan"
                          type="radio"
                          value="standard"
                          className="form-radio h-4 w-4 text-indigo-600 transition duration-150 ease-in-out cursor-pointer"
                        />
                        <span className="font-medium text-gray-900">
                          Lite Plan
                        </span>
                      </label>
                      <p
                        id="plan-option-pricing-0"
                        className="ml-6 pl-1 text-sm leading-5 md:ml-0 md:pl-0 md:text-center"
                      >
                        <span className="font-medium text-gray-700 bg-gray-100 px-3 py-2 rounded-md">
                          Free Trial
                        </span>
                        <span className="line-through px-2">($29 / mo)</span>
                      </p>
                      <p
                        id="plan-option-limit-0"
                        className="ml-6 pl-1 text-sm leading-5 md:ml-0 md:pl-0 md:text-right"
                      >
                        Shield Google and Facebook Pixel
                      </p>
                    </div>
                  </li>
                </ul>
              </fieldset>
            </div>
            <div>
              <label
                htmlFor="first_name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                First name
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  aria-label="First Name"
                  name="first_name"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div>
              <label
                htmlFor="last_name"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Last name
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  aria-label="Last name"
                  name="last_name"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="company"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Company
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  aria-label="Company"
                  name="company"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label
                htmlFor="domain"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Domain
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  aria-label="Domain"
                  name="domain"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>

            <div className="sm:col-span-2">
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-5 text-gray-700"
              >
                Work Email
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <input
                  aria-label="Email"
                  name="email"
                  type="email"
                  required
                  className="form-input py-3 px-4 block w-full transition ease-in-out duration-150"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  <input
                    aria-label="Privacy"
                    aria-checked="false"
                    type="checkbox"
                    required
                  />
                </div>
                <div className="ml-3">
                  <p className="text-base leading-6 text-gray-500">
                    By selecting this, you agree to the
                    <Link
                      className="font-medium text-gray-700 underline px-2"
                      to="/privacy-policy/"
                    >
                      Privacy Policy
                    </Link>
                    .
                  </p>
                </div>
              </div>
            </div>
            <div className="sm:col-span-2">
              <span className="w-full inline-flex rounded-md shadow-sm">
                <button
                  type="submit"
                  className="w-full inline-flex items-center justify-center px-6 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition ease-in-out duration-150"
                >
                  Sign up now
                </button>
              </span>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};
